<template>
  <div>
    <search-tool :title="$t('nav.registryList')" @search="search" @refresh="getRegistryList(currentPage)">
      <template #handle-buttons>
        <el-button size="small" type="primary" @click="$router.push('/admin/registry/add')">添加镜像仓库</el-button>
      </template>
    </search-tool>

    <el-table :data="registryList" v-loading="loading">
      <el-table-column label="#">
        <template slot-scope="scope">
          <div v-if="scope.row.kind == 'RegistryAWS'">
            <img :src="aws" />
          </div>
          <div v-if="scope.row.kind == 'RegistryDocker' || scope.row.kind == 'RegisryDocker'">
            <img :src="docker" />
          </div>
          <div v-if="scope.row.kind == 'RegistryGCP'">
            <img :src="gcp" />
          </div>
          <div v-if="scope.row.kind == 'RegistryAliACR'">
            <img :src="aliyun" />
          </div>
          <div v-if="scope.row.kind == 'RegistryTencentTCR'">
            <img :src="tencentcloud" />
          </div>
          <div v-if="scope.row.kind == 'RegistryHuaweiSWR'">
            <img :src="huaweicloud" />
          </div>
          <div v-if="scope.row.kind == 'RegistryHarbor' || scope.row.kind == 'RegistryHarborv2'">
            <img :src="harbor" />
          </div>
          <div v-if="scope.row.kind == 'RegistryUhub'">
            <img :src="ucloud" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" sortable :label="$t('name')"> </el-table-column>
      <el-table-column label="所属集群">
        <template slot-scope="scope">
          <div v-for="item in scope.row.providers" :key="item.uuid">
            <div class="title">{{ item.cluster }}</div>
            <div class="desc">{{ item.kind }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="kind" :label="$t('vendor')"> </el-table-column>
      <el-table-column prop="regionID" :label="$t('region')"> </el-table-column>

      <el-table-column prop="host" label="Host" width="400"> </el-table-column>
      <el-table-column width="100" :label="$t('handle.handle')">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <button class="table-row-handle">
              <i class="el-icon-more-outline"></i>
            </button>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="edit(scope.row)">
                  {{ $t("handle.edit") }}
                </div>
              </el-dropdown-item>

              <el-dropdown-item>
                <div @click="deleteRegistry(scope.row)">
                  {{ $t("handle.delete") }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout=" prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20, 30, 50]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
    </el-pagination>

    <el-dialog
      :title="$t('handle.edit')"
      :visible.sync="dialogVisible"
      top="30px"
      :close-on-click-modal="false"
      width="800px"
    >
      <registry-form :form="form" type="list" ref="form" v-if="dialogVisible" />
      <span slot="footer">
        <el-button type="primary" @click="submit" :loading="updateLoading" size="small">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import provider from "@/mixins/provider";
import { registryList, updateRegistry, deleteRegistry } from "api/registry";
import RegistryForm from "./Form.vue";
import { cloneDeep, includes } from "lodash";

export default {
  components: { RegistryForm },
  mixins: [provider],

  data() {
    return {
      registryList: [],
      loading: false,
      dialogVisible: false,

      total: undefined,
      currentPage: 1,
      currentPageSize: 10,
      searchText: "",

      form: {},
      updateLoading: false
    };
  },
  methods: {
    getRegistryList(page = 1, pageSize = this.currentPageSize, search = [this.searchText]) {
      this.loading = true;
      this.currentPage = page;

      registryList(this.organization, { page, pageSize, search }).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.registryList = response.data.items;
          this.total = response.data.total;
        }
      });
    },

    search(searchText) {
      this.searchText = searchText;
      this.getRegistryList(1);
    },

    currentChange(page) {
      this.currentPage = page;
      this.getRegistryList(page);
    },

    sizeChange(size) {
      this.currentPageSize = size;
      this.getRegistryList(1, size);
    },

    edit(row) {
      this.dialogVisible = true;
      this.form = cloneDeep(row);

      this.$nextTick(() => {
        this.$refs["form"].providerRegistryBindings = this.form.bindings.providerRegistryBindings.map(
          item => item.providerUUID
        );
      });
    },

    deleteRegistry(item) {
      this.$confirm(this.$t("delete", [item.name]), this.$t("prompt"), {
        type: "warning"
      })
        .then(() => {
          deleteRegistry(item).then(response => {
            if (response.code === 0) {
              this.getRegistryList(this.currentPage);
            }
          });
        })
        .catch(() => {});
    },

    submit() {
      this.$refs["form"].$refs["form"].validate(valid => {
        if (valid) {
          let providerRegistryBindings = this.$refs["form"].providerRegistryBindings;

          if (this.form.bindings.providerRegistryBindings.length > 0) {
            this.form.bindings.providerRegistryBindings.forEach(item => {
              if (!includes(providerRegistryBindings, item.providerUUID)) {
                this.$set(item, "operation", "DELETE");
              }
            });

            providerRegistryBindings.forEach(item => {
              if (
                !includes(
                  this.form.bindings.providerRegistryBindings.length > 0
                    ? this.form.bindings.providerRegistryBindings.map(item => item.providerUUID)
                    : [],
                  item
                )
              ) {
                this.form.bindings.providerRegistryBindings.push({
                  providerUUID: item,
                  registryUUID: this.form.uuid
                });
              }
            });
          }

          this.updateLoading = true;
          updateRegistry(this.form).then(response => {
            this.updateLoading = false;
            if (response.code === 0) {
              this.dialogVisible = false;
              this.getRegistryList(this.currentPage);
            }
          });
        }
      });
    },

    filterReady(value, row) {
      return row.ready === value;
    }
  },

  mounted() {
    this.getRegistryList();
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    }
  }
};
</script>
